import * as React from "react";
// import { StaticImage } from "gatsby-plugin-image";
// import { Link } from "gatsby";
// import { useBreakpoint } from "gatsby-plugin-breakpoints";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Page from "../../components/Layout/Page";
import Container from "../../components/Layout/Container";
// import BtnOutlined from "../components/reusable/BtnOutlined";
// import BtnFilled from "../../components/reusable/BtnFilled";
// import ContactBlock from "../../components/ContactBlock";

import CasestudiesCarousel from "../../components/CaseStudies";

// import HomeOverview from "../components/HomeOverview";
// markup

// import { ArrowDown } from "../images/svgs";

const CaseStudiesPage = () => {
  // const breakpoints = useBreakpoint();

  return (
    <Page className={"case-studies"}>
      <section className="hero">
        <Container>
          <h1>
            <b>Case Studies</b>
          </h1>
        </Container>
      </section>

      <section className="case-studies">
        <Container>
          <CasestudiesCarousel type="list" />
        </Container>
      </section>
    </Page>
  );
};

export default CaseStudiesPage;
